import React from 'react';
import ReactPlayer from 'react-player';
import playButton from '../../images/play_button.svg';
import vidBg from '../../images/vid-bg2.png';
import { CgChevronDoubleDown } from 'react-icons/all';

import { useTranslation } from 'gatsby-plugin-react-i18next';

const Video: React.FC<{}> = (props) => {
  const { t } = useTranslation();

  return (
    <section id="video" className="w-full flex-col">
      <div className="flex flex-col justify-center items-center">
        <div className="w-4/5 md:w-2/3">
          <div className="flex justify-center my-4">
            <CgChevronDoubleDown size={70} />
          </div>
          <div className="flex justify-center mt-10 h-48 md:h-screen-77 hover:bg-coming-2 rounded-xl border-4 border-white">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=pOvOLA3NtTc"
              light={vidBg}
              controls={true}
              width={'100%'}
              height={'100%'}
              playing={true}
              style={{
                borderRadius: '0.5rem',
                borderStyle: 'solid',
                borderColor: '#ffffff',
                padding: '4px',
              }}
              playIcon={<img className="h-24" src={playButton} />}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Video;
